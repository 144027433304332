<template>
  <v-card width="100%">
    <!-- <v-card-title
      class="text-h5 mb-4 justify-center"
      style="background-color: #d9c7a3"
      ><v-spacer />
      {{ $t("links.title") }}
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title> -->
    <v-toolbar color="#d9c7A3" class="mb-2">
      <v-spacer />
      <v-toolbar-title>   
        {{ $t('links.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
        <v-md-preview :text="text" preview-class="vuepress-markdown-body"></v-md-preview>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: "Links",
  data: () => ({

  }),
  methods: {
    close() {
      this.$emit("closedialog", false);
    },
  },
  computed: {
    ...get('pages', {
      text: 'page@text'
    })
  },
};
</script>